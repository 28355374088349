import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useParams } from "react-router-dom";
import axiosIns from "../components/axiosIns";
import Button from "react-bootstrap/Button";
import '../App.css';
import { FaSync } from 'react-icons/fa';

const styles = [
  ` .marquee {
    font-size: 24px;
    color: #06603a;
    margin-bottom : 10px;
  }
  `,
];

function Doctor() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const params = useParams();
  const getData = async () => {
    try {
      const res = await axiosIns.post("/doctor_list", {mode : params.id});
      setData(res.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response.data.msg === "token") {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
      console.log(err.response);
    }
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredDoctors = data.filter((row) => {
    return row.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const doctor = filteredDoctors.map((row) => {
    return (
      <div className="col-md-4 mb-4" key={row._id}>
        <Link to={`/doctor_profile/${row._id}`}>
          <div className="profile-card" style={{ borderRadius: 20 }}>
            <div className="profile-content" style={{backgroundColor : "#f7fffd"}}>
              <div className="profile-image">
                <img
                  className="img"
                  src={row.picture ? row.picture : "/images/noimg.jpg"}
                  alt="doctor"
                />
              </div>
              <div className="desc">
                <h5>{row.name}</h5>
                <br />
                <p>{row.available_time}</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  });

  return (
    <>
        <style>{styles}</style>
      <div className="container mt-4">
        <input style={{width :"30%",borderRadius:"50px",marginRight:"870px",padding:"20px", fontSize : "14px"}}
          type="text"
          className="form-control"
          placeholder="Search Doctor by Name"
          value={searchTerm}
          onChange={handleSearch}
        />
          <a href="/doctor" onClick={() => window.location.reload()}>
            <FaSync style={{fontSize :'2.5rem',color :' #007bff',cursor : "pointer"}} />
          </a>
      </div>
      {loading ? (
        <table className="loader">
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div title="Doctor" data={data} pagination highlightOnHover />
      )}
      <div className="container">
        <div className="row">{doctor}</div>
      </div>
    </>
  );
}

export default Doctor;
