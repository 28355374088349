import React, { useState } from 'react';
import '../register.css';
import axios from 'axios';

const AddCompany = () => {
  const [companyData, setData] = useState(({
    company_name: "",
    password: "",
    contact_person:"",
    no_of_emp :"",
    address :"",
    mobile :"",
    company_type :"",
    email :"",
  }))
  const {company_name , password, contact_person, no_of_emp, address, mobile, company_type, email} = companyData

  const handleChange = ((e)=>{
    setData({...companyData, [e.target.name]: e.target.value})
  })

  const handleSubmit = async(event) => {
    event.preventDefault();
    const userToken = localStorage.getItem('token')
    axios.defaults.headers.post['Content-Type'] = "application/json";
    axios.defaults.headers.common['Authorization'] = userToken
    await axios.post("https://digisailor.loca.lt/api/Auth/admin/company_register", companyData)
    .then((res) => {
      if(res.data.status === true){
        alert(res.data.msg)
        window.location.replace("/")
      }
      else alert(res.data.msg)
    }).catch((err)=>{
      alert("Please Try Again...")
    })
  };

  return (
    <>
    <div className="container">
  <div className="row">
    <form  onSubmit={handleSubmit}>
        <div className="row">
            <h1 style={{alignItems: "center"}}>Add the Company Details Below</h1> 
            <div className="col-md-4">
                <label>Company Name</label>
            <input type="text" className="form-control" value={company_name} onChange={handleChange} placeholder="Company Name" name="company_name" />
            </div>
            <div className="col-md-4">
                <label>Type of Company</label>
            <input type="text" className="form-control" value={company_type} onChange={handleChange} placeholder="Type of Company" name="company_type" />
            </div>
            <div className="col-md-4">
                <label>Mobile Number</label>
            <input type="text" className="form-control" value={mobile} onChange={handleChange} placeholder="Mobile Number" name="mobile" />
            </div>
            <div className="col-md-4">
                <label>Mail Id</label>
            <input type="text" className="form-control" value={email} onChange={handleChange} placeholder="Email Id" name="email" />
            </div>
            <div className="col-md-4">
                <label>Number Of Employees</label>
            <input type="text" className="form-control" value={no_of_emp} onChange={handleChange} placeholder="No.of Employees" name="no_of_emp"/>
            </div>
            <div className="col-md-4">
                <label>Contact Person</label>
            <input type="text" className="form-control" value={contact_person} onChange={handleChange} placeholder="Contact person" name="contact_person" />
            </div>
            <div className="col-md-4">
                <label>Company Address</label>
            <input type="text" className="form-control" value={address} onChange={handleChange} placeholder="Company Address" name="address" />
            </div>
            <div className="col-md-4">
                <label>Enter Password</label>
            <input type="password" className="form-control" value={password} onChange={handleChange} placeholder="Enter Your Password" name="password" />
            </div>
            {/* <div className="col-md-4">
                <label>Confirm Password</label>
            <input type="text" className="form-control" placeholder="Confirm Password" name="Confirm Password" />
            </div> */}
            <div className="col-md-12" align="center" style={{padding : "10px"}}><button onClick={handleSubmit} type="button" className="btn btn-success">Save</button></div>
        </div>
    </form>
  </div>
</div>
{/* <form classNameName="registration-form" onSubmit={handleSubmit}>
      <label classNameName="form-label">
        Company Name:
        <input classNameName="form-input" type="text" value={companyName} onChange={(event) => setCompanyName(event.target.value)} />
      </label>
      <label classNameName="form-label">
        First Name:
        <input classNameName="form-input" type="text" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
      </label>
      <label classNameName="form-label">
        Last Name:
        <input classNameName="form-input" type="text" value={lastName} onChange={(event) => setLastName(event.target.value)} />
      </label>
      <label classNameName="form-label">
        Email:
        <input classNameName="form-input" type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
      </label>
      <label classNameName="form-label">
        Phone:
        <input classNameName="form-input" type="tel" value={phone} onChange={(event) => setPhone(event.target.value)} />
      </label>
      <label classNameName="form-label">
        Password:
        <div classNameName="password-input-wrapper">
          <input classNameName="form-input password-input" type={showPassword ? 'text' : 'password'} value={password} onChange={(event) => setPassword(event.target.value)} />
          <button classNameName="password-toggle-button" type="button" onClick={handlePasswordToggle}>
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
      </label>
      <label classNameName="form-label">
        Confirm Password:
        <input classNameName="form-input" type="password" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
      </label>
      <button classNameName="form-button" type="submit">Register</button>
    </form> */}
    </>
  );
};

export default AddCompany;
