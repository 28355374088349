import React from 'react';
import { Col, Button, Row, Container, Card, Form } from 'react-bootstrap';


export default function Adddetail() {
  return (
    <div className='Scroll'>
      <Container>
        <Row className="vh-1000 d-flex justify-content-center align-items-center">
          <Col md={10} lg={12} xs={12}>
            <Card className="px-12 lg-12">
              <Card.Body>
                <div className="mb-3 mt-lg-12">
                  <h2 className="fw-bold mb-4 text-center text-uppercase ">
                    Add the Details Below
                  </h2>
                  <div className="mb-8 lg-5">
                    <Form>
                      <Form.Group className="mb-5" controlId="Name">
                        <Form.Label className="text-center">Company Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Name" />
                      </Form.Group>
                      <Form.Group className="mb-5" controlId="Name">
                        <Form.Label className="text-center">Type of Company</Form.Label>
                        <Form.Control type="text" placeholder="Enter Name" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Name">
                        <Form.Label className="text-center">Mobile Number</Form.Label>
                        <Form.Control type="text" placeholder="Enter Mobile Number" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Email address
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Name">
                        <Form.Label className="text-center">Number of Employees</Form.Label>
                        <Form.Control type="text" placeholder="Number of Employees" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Name">
                        <Form.Label className="text-center">Contact Person</Form.Label>
                        <Form.Control type="text" placeholder="Contact Person" />
                      </Form.Group>
                      <Form.Group
                        className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Name">
                        <Form.Label className="text-center">Company Address</Form.Label>
                        <Form.Control type="text" placeholder="Company Address" passwordtoggle="true" />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"controlId="formBasicPassword" >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" passwordtoggle="true" />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Add
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}