import React from "react";
import "./App.css";
import Sidebar from "./components/Sidebar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Request from "./pages/Request";
import Doctor from "./pages/Doctor";
import DataSection from "./pages/DataSection";
import Offers from "./pages/Offers";
import Dashboard from "./pages/Dashboard";
import Login from "./Login";
import Adddetail from "./pages/Adddetails";
import AddDoctor from "./pages/Doctor/AddDoctor";
import AddCompany from "./pages/company/AddCompany";
import Employee from "./pages/Employee";
import BookAppointment from "./pages/Employee/BookAppointment";
import DoctorProfile from "./pages/Doctor/DoctorProfile";
import EmployeeProfile from "./pages/Employee/EmployeeProfile";
import Logout from "./Logout";
import { ToastContainer } from "react-toastify";
import CompanyProfile from "./pages/company/CompanyProfile";
import Sliding from '../src/components/Sliding';
import ProfileCircle from "./pages/company/Profile";
import ForgotPassword from "./pages/ForgotPassword";
import NewPassword from "./pages/NewPassword";
import NotFound from "./components/404";
import HospitalList from "./pages/HospitalList";
import Arputham from './pages/NetworkHospitals/Arputham';
import RR from './pages/NetworkHospitals/RR';
import Malar from './pages/NetworkHospitals/Malar';
import DiyaHeart from './pages/NetworkHospitals/DiyaHeart';
import Rajeswari from './pages/NetworkHospitals/Rajeswari';
import Sun from './pages/NetworkHospitals/Sunscans';
import Santo from './pages/NetworkHospitals/Santo';
import Radhu from './pages/NetworkHospitals/Radhu';
import Bedasta from './pages/NetworkHospitals/Bedesta';
import Genga from './pages/NetworkHospitals/Gengadental';
import Annai from './pages/NetworkHospitals/Annai';

function App() {
  const isLogin = localStorage.getItem("isLogin");
  return (
    <>
    <ToastContainer />
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <Dashboard />{" "}
                </Sidebar>
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/doctor"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <Doctor />
                </Sidebar>
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/employee"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <Employee />
                </Sidebar>
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/request"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <Request />
                </Sidebar>
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/data"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <DataSection />
                </Sidebar>
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/offers"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <Offers />
                </Sidebar>
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/add"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <Adddetail />
                </Sidebar>{" "}
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/add_doctor"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <AddDoctor />
                </Sidebar>{" "}
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/add_company"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <AddCompany />
                </Sidebar>{" "}
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/book_appointment/:id"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <BookAppointment />
                </Sidebar>{" "}
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/doctor_profile/:id"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <DoctorProfile />
                </Sidebar>{" "}
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/doctor_list/:id"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <Doctor />
                </Sidebar>{" "}
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/employee_profile/:id"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <EmployeeProfile />
                </Sidebar>{" "}
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
        <Route
          path="/logout"
          element={
            isLogin ? (
              <>
                {" "}
                <Sidebar> <Sliding /> <ProfileCircle />
                  {" "}
                  <Logout />
                </Sidebar>{" "}
              </>
            ) : (
              <Login />
            )
          }
        ></Route>
                <Route path="/myprofile" element= { isLogin ? <> <Sidebar> <Sliding /> <ProfileCircle /> <CompanyProfile /></Sidebar> </> : <Login/>}></Route>
                <Route path="/forgot_password" element= { <ForgotPassword/> }></Route>
                <Route path="/new_password/:token" element= { <NewPassword/> }></Route>
                <Route path="*" element= { <NotFound/> }></Route>
                <Route path="/networkhospitals" element= { isLogin ? <> <Sidebar> <Sliding /> <ProfileCircle /> <HospitalList /></Sidebar> </> : <Login/>}></Route>
                <Route
            path="/Arputham Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Arputham />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/RR Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <RR />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Malar Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Malar />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Diya Heart Center"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <DiyaHeart />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Rajashwari Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Rajeswari />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Sun Scans"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Sun />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Santho Scans"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Santo />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Radhu Hospital"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Radhu />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Bedasta Clinic"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Bedasta />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Genga Dental Care"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Genga />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
          <Route
            path="/Annai Eye Clinic"
            element={
              isLogin ? (
                <>
                  {" "}
                  <Sidebar>
                    {" "}
                    <Sliding /> <ProfileCircle /> <Annai />
                  </Sidebar>{" "}
                </>
              ) : (
                <Login />
              )
            }
          ></Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
