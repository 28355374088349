import React, {useState, useEffect}from 'react';
import axios from 'axios';
import '../css/Dashboard.css'
import axiosIns from '../components/axiosIns';
import { Link} from 'react-router-dom';
import ProfileCircle from './company/Profile';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import ReactModal from 'react-modal';
import {AiFillCloseCircle} from 'react-icons/ai'

const styles = [
  ` .marquee {
    font-size: 24px;
    color: #06603a;
    margin-bottom : 10px;
  }
  `,
];

const Dashboard= () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] =useState(true);
    const [adsList, setadsList] = useState([]);

    const REACT_APP_ADMIN_URL = process.env.REACT_APP_ADMIN_URL
    const getData = async()=>{
        await axios.post(REACT_APP_ADMIN_URL + '/service_list')
        .then((res)=>{
            console.log(res)
            setData(res.data.data)
            setLoading(false);
            console.log(data)
        }).catch((err)=>{
            console.log(err)
        })
    } 

    const getAds = async()=>{
        await axiosIns.post('/ads_list')
        .then((res) => {
          setadsList(res.data.data)
          console.log(data)
        }).catch((err)=>{
          if (err.response.data.msg === "token") {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
          console.log(err.response)
        })
      }

    useEffect(()=>{
        const checkTokenExpiration = setInterval(() => {
            const expirationTime = localStorage.getItem("tokenExpirationTime");
            if (expirationTime && Date.now() > parseInt(expirationTime)) {
              localStorage.clear("isLogin");
              window.location.reload("/");
            }
          }, 1000);
      
            clearInterval(checkTokenExpiration);
            getData();
            getAds();
    }, []) 
    console.log(data)

    const service = data.map((row) => {
      return (
        <div className="col-md-4 mb-4" key={row._id}>
          <Link to={`/doctor_list/${row._id}`}>
          <div className="profile-content">
            <div className="profile-image">
              <img
                className="img"
                src={row.picture ? row.picture : "/images/noimg.jpg"}
                alt="first user"
              />
            </div>
            <div className="desc">
              <h5>{row.name}</h5>
            </div>
          </div>
          </Link>
          </div>
      );
    });

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,       
      autoplaySpeed: 3000,
    };

    const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const openModal = (ad) => {
    setSelectedAd(ad);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedAd(null);
    setModalOpen(false);
  };

  const adSlides = adsList.concat(adsList).map((row, index) => (
    <div key={index} className="ad-slide" onClick={() => openModal(row)}>
      {row.picture ? (
        <div className="card" style={{ width: "300px", height: "300px",marginRight:"50px", marginLeft: "50px"}}>
          <img
            className="card-img-top"
            src={row.picture}
            alt="Card image"
            style={{ width: "300px", height: "300px" }}
          />
        </div>
      ) : null}
    </div>
  ));


return(    
    <>
        <style>{styles}</style>
        <div className="App" style={{ width: "100%" }}>
        {/* <marquee>
          <div class="container">{adSlides}</div>
        </marquee>{" "} */}
        <Slider {...settings}>
          {adSlides}
        </Slider> <br/> <br/> <br/>
      <br /> <br /> <br />
        
        <ProfileCircle />
        
    {loading ? (
          <table className="loader">
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div
            title="Dashboard"
            data={data}
            pagination
            highlightOnHover
          />
        )}

    <div className="container1">
        <div className="team_container">  </div>
        <div className="row">
            {service}
        </div>    
    </div>

    <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Ad Popup"
      >
        {selectedAd && (
          <div className="modal-content">
            <img src={selectedAd.picture} alt="Ad" />
        <AiFillCloseCircle onClick={closeModal} className="modal-close"/>
          </div>
        )}
      </ReactModal>
      </div>
    </>
)
     
    
};

export default Dashboard;