import React, { useState } from "react";
import { redirect } from "react-router-dom";

import {
  FaTh,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaHospitalAlt,
  FaThList,
  FaBars,
  FaTags,

  FaSignOutAlt,
  FaUserCheck,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/",
      name: "Dashboard",
      pic: <FaTh />,
    },
    {
      path: "/doctor",
      name: "Doctor",
      pic: <FaUserAlt />,
    },
    {
      path: "/employee",
      name: "Employee",
      pic: <FaRegChartBar />,
    },
    // {
    //   path: "/request",
    //   name: "Request",
    //   pic: <FaCommentAlt />,
    // },
    // {
    //   path:"/data",
    //   name:"Data",
    //   pic:<FaCommentAlt/>
    // },
    {
      path: "/offers",
      name: "Offers",
      pic: <FaThList />,
    },
    {
      path:"/networkhospitals",
      name:"Network Hospitals",
      pic:<FaHospitalAlt/>
    },
    {
      path: "/logout",
      name: "Sign Out",
      pic: <FaSignOutAlt />,
    },
  ];
  return (
    <div className="container">
      <div className="sidebar" style={{ width: isOpen ? "200px" : "53px" }}>
        <div className="top_section">
        <h1 className="logo" style={{ display: isOpen ? "block" : "none" }}>
            <img src="/Agan_logo.png" width={125} height={75} alt="logo" />
          </h1>
          <div className="bars" style={{ marginLeft: isOpen ? "0px" : "0px" }}>
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeclassName="active"
          >
            <div className="icon">{item.pic}</div>
            <div
              className="link_text"
              style={{ display: isOpen ? "block" : "none" }}
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>

      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
