import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { Alert } from "../src/components/Tostify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


function Login() {
  const [loginData, setData] = useState({
    email: "",
    password: "",
    role: 2,
  });
  const { email, password, role } = loginData;
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setData({ ...loginData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    //Prevent page reload
    e.preventDefault();
    const REACT_APP_ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
    const data = { email: email, password: password, role: role };
    await axios
      .post(REACT_APP_ADMIN_URL + "/sign_in", data)
      .then((res) => {
        if (res.data.status === true) {
          localStorage.setItem("isLogin", true);
          localStorage.setItem("token", res.data.data.userToken);
          localStorage.setItem("id", res.data.data.ID);
          localStorage.setItem("name", res.data.data.Name);
          localStorage.setItem("role", res.data.data.Role);
          localStorage.setItem("Ref_id", res.data.data.Ref_id);

          const expirationTime = Date.now() + res.data.data.expiresIn * 50000;
          localStorage.setItem("tokenExpirationTime", expirationTime);
          window.open("https://www.company.aganhealthcare.com");
          Alert ('succ', res.data.msg);
        }
        else {
          console.log(res.data.msg);
          Alert ("error", "User Not Found");
        }
      })
      .catch((err) => {
        console.log(err);
        Alert ("error", err);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
    <div className="app">
      <div className="login-form">
        <div className="title">Kaviyam Healthcare</div>
        <div className="form" style={{ width: "300px" }}>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                type="text"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Enter Your Username/Email"
                required
              />
            </div>
            <div className="input-container">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={handleChange}
              placeholder="Enter Your Password"
              required
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              className="password-icon"
              onClick={toggleShowPassword}
            />
            </div>
            <div className="forgot-password-container">
            <Link to="/forgot_password" style={{fontSize :"14px"}}>Forgot Password?</Link>
          </div>
            <div className="button-container">
              <input type="Submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  );
}

export default Login;
