import React, { useState, useEffect } from "react";
import axios from "axios";

const styles = `
  .profile-card{
    width: 33% !important;
  }

  .profile-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .desc {
    text-align: left;
  }

  .desc h5 {
    margin-bottom: 0.5rem;
  }
  .off_rad{ width: 100% !important}

  .marquee {
    font-size: 24px;
    color: #06603a;
    margin-bottom : 10px;
  }

`;

const Offers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const REACT_APP_ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

  const getData = async () => {
    await axios
      .post(REACT_APP_ADMIN_URL + "/offer_list")
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setLoading(false);
        console.log(data);
      })
      .catch((err) => {
        if (err.response.data.msg == "token") {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
        console.log(err.response);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);

  const service = data.map((row) => {
    return (
      <div className="col-md-4 mb-4">
        <div className="profile-card profile-radius off_rad" key={row.id}>
          <div className="profile-content" style={{backgroundColor : "#f7fffd"}}>
            <div className="desc">
              <h5>{row.title}</h5>
              <div className="profile-image">
              <img
                className="img"
                style={{ borderRadius: "0" }}
                src={row.picture ? row.picture : "/images/noimg.jpg"}
                alt="first user"
              />
            </div>
              <h5>{row.amount}</h5>
              <p style={{ overflow: "hidden", textOverflow: "ellipsis", overflowY: "auto", height :"55px" }}>
                {row.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <>
        <style>{styles}</style>
      {loading ? (
        <table className="loader">
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div title="Offers" data={data} pagination highlightOnHover />
      )}
      <style>{styles}</style>
      <div className="container1">
        <div className="team_container"> </div>
        <div className="row">{service}</div>
      </div>
    </>
  );
};

export default Offers;
