import React,  {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import data from '../data.json';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosIns from '../components/axiosIns';

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Gender',
    selector: 'gender',
    sortable: 'true'
  },
  {
    name: 'Experience',
    selector: 'experience',
    sortable:'true'
  },
  {
    name: 'Phone',
    selector: 'mobile',
    sortable: 'true',
  },
  {
    name : 'Clinic Address',
    selector: 'clinic_address',
    sortable:'true'
  },
  {
    name : 'Date',
    selector :'dob'
  },
  // {
  //   name :'Status',
  //   selector :'status',
  // },
];

function DataSection() {
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  
  const getData = async()=>{
    await axiosIns.post('/doctor_list')
    .then((res) => {
      setData(res.data.data)
      setLoading(false)
      console.log(data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getData()
  }, [])
  return (
    <div className="App" style={{width:"100%"}}>
    <div align="right"><Button variant="primary">Add</Button></div>
    {loading ? (
        <table><tbody><tr><td>loading</td></tr></tbody></table>
      ) : (
      <DataTable
        title="Deleted Employees & Doctors"
        columns={columns}
        data={data}
        pagination 
        highlightOnHover
      />)}
    </div>
  );
}

export default DataSection;