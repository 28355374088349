import React from 'react'

const styles = [
   `
  .marquee {
    font-size: 24px !important;
    color: #06603a;
    margin-bottom : 10px;
  }
`,
];

const Sliding = () => {
  return (<>
  <style>{styles}</style>
    <div>
     <marquee className="marquee" scrollamount="3">Call us and Whatsapp 9385920095</marquee>
    </div>
    </>
  )
}

export default Sliding