import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import axiosIns from "../../components/axiosIns";

export default function DoctorProfile() {
  const styles = `
      p {
        font-size: 14px !important;
      }
      .row{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
      }
      .marquee {
        font-size: 24px;
        color: #06603a;
        margin-bottom : 10px;
      }
    `;
  const [loading, setLoading] = useState(true);
  const [employeeData, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    mode_of_consultant: "",
    clinic_address: "",
    picture: "",
    aboutus: "",
  });
  const {
    name,
    email,
    mobile,
    mode_of_consultant,
    clinic_address,
    picture,
    aboutus,
  } = employeeData;

  const params = useParams();
  const getData = async () => {
    if (params.id) {
      await axiosIns
        .post("/get_doctor", { id: params.id })
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          if (err.response.data.msg == "token") {
            localStorage.clear("isLogin");
            window.location.reload("/");
          }
          console.log(err.response);
        });
    } else window.location.replace("/");
  };
  useEffect(() => {
    const checkTokenExpiration = setInterval(() => {
      const expirationTime = localStorage.getItem("tokenExpirationTime");
      if (expirationTime && Date.now() > parseInt(expirationTime)) {
        localStorage.clear("isLogin");
        window.location.reload("/");
      }
    }, 1000);

    clearInterval(checkTokenExpiration);
    getData();
  }, []);

  return (
    <section>
      <style>{styles}</style>
      <h2>Doctor Profile</h2>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src={picture ? picture : "/images/noimg.jpg"}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: "250px", height: "250px" }}
                  fluid
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Full Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{email}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Phone</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{mobile}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Specialist</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{Array.isArray(mode_of_consultant) ? mode_of_consultant.join(", ") : (typeof mode_of_consultant === 'string' ? mode_of_consultant : '')}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Clinic Address</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">
                      {clinic_address}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            <MDBCard>
              <MDBRow>
                <h5>About</h5>
                <p>{aboutus}</p>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
