import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { Alert } from "../components/Tostify";
import axiosIns from "../components/axiosIns";

const styles = [`
.forgot-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7fffd;
}

.forgot-password-form {
  background-color: #f7fffd;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}

.forgot-password-form h2 {
  margin-top: 0;
}

.forgot-password-form form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forgot-password-form input[type="text"] {
  height: 30px;
  padding: 5px;
}

.forgot-password-form button[type="submit"] {
  background-color: #6cf0c2;
  border: none;
  color: #black;
  padding: 10px 15px;
  cursor: pointer;
}

.forgot-password-form button[type="submit"]:hover {
  background-color: #5ab6a8;
}

.forgot-password-form button[type="submit"]:focus {
  outline: none;
}

.forgot-password-form button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  font-size: 14px !important;
  color : #06603a;
}
`];

function NewPassword() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const params = useParams();
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            Alert("error", "New password and confirm password do not match.");
            return;
        }

        const data = { "new_password" : newPassword, "token": params.token };
        try {
            const res = await axiosIns.post(`/company_update_password`, data);
            if (res.data.status === true) {
                Alert("succ", res.data.msg);
            } else {
                Alert("error", res.data.msg);
            }
        } catch (error) {
            console.log(error);
            Alert("error", "An error occurred. Please try again.");
        }
    };

    return (
        <>
            <style>{styles}</style>
            <div className="forgot-password-page">
                <div className="forgot-password-form">
                    <h2>Forgot Password</h2>
                    <br />
                    <br />
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="newPassword"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            placeholder="Enter new password"
                            required
                        />
                        <input
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            placeholder="Confirm password"
                            required
                        />
                        {newPassword !== confirmPassword && (
                            <div className="password-error" style={{ fontSize: "14px", color: "#06603a" }}>New password and confirm password do not match.</div>
                        )}
                        <br />
                        <button type="submit">Submit</button>
                    </form>
                    <Link to="/login">
                        <span style={{ display: "flex", alignItems: "center", marginTop: "10px", fontSize: "14px" }}>
                            <FiArrowLeft style={{ marginRight: "5px" }} />
                            Back to Login
                        </span>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default NewPassword;
