import React,  {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
// import data from '../data.json';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'mobile',
    sortable: true,
  },
  // {
  //   name: 'Email',
  //   selector: 'email',
  //   sortable: true,
  // },
  {
    name: 'Requests & Notifications',
    selector: 'request',
  },
  {
    name: 'Date',
    selector: 'dob',
  },
];

function Request() {
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  
  const getData = async()=>{
    axios.defaults.headers.common['Authorization'] = userToken
    await axios.post('https://digisailor.loca.lt/api/Auth/admin/doctor_list')
    .then((res) => {
      setData(res.data.data)
      setLoading(false)
      console.log(data)
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    const checkTokenExpiration = setInterval(() => {
        const expirationTime = localStorage.getItem("tokenExpirationTime");
        if (expirationTime && Date.now() > parseInt(expirationTime)) {
          localStorage.clear("isLogin");
          window.location.reload("/");
        }
      }, 1000);
  
        clearInterval(checkTokenExpiration);
        getData();
}, [])
  return (
<div className="App" style={{width:"100%"}}>
    <div align="right"><Button variant="primary">Add</Button></div>
    {loading ? (
        <table><tbody><tr><td>loading</td></tr></tbody></table>
      ) : (
      <DataTable
        title="Mail Request"
        columns={columns}
        data={data}
        pagination
        highlightOnHover
      /> )}
    </div>
  );
}

export default Request;