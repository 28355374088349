import React, { useState, useEffect } from 'react';
import '../register.css';
import axios from 'axios';

const AddDoctor = () => {
  const [doctorData, setData] = useState(({
    name: "",
    email: "",
    age: "",
    mobile: "",
    gender: "", 
    experience: "",
    doctor_id: "",
    mode_of_consultant: "", 
    available_time: "", 
    clinic_name: "",
    clinic_address: "",
    picture: "",
    password: ""
  }))
  const {name, email, age, mobile, gender, experience, doctor_id, 
    mode_of_consultant, available_time, clinic_address, clinic_name, picture, password} = doctorData
  const handleSelectChange = (event) => {
    const values = Array.from(event.target.selectedOptions, option => option.value);
    setData({...doctorData, ['mode_of_consultant']: values})
  }; 
    
  const handleChange = ((e)=>{
    setData({...doctorData, [e.target.name]: e.target.value})
  })

  const handleSubmit = async(event) => {
    event.preventDefault();
    const userToken = localStorage.getItem('token')
    axios.defaults.headers.post['Content-Type'] = "application/json";
    axios.defaults.headers.common['Authorization'] = userToken
    await axios.post("https://digisailor.loca.lt/api/Auth/admin/doctor_register", doctorData)
    .then((res) => {
      if(res.data.status === true){
        alert(res.data.msg)
        window.location.replace("/")
      }
      else alert(res.data.msg)
    }).catch((err)=>{
      alert("Please Try Again...")
    })
  };

  const [servicelist, setserviceData] = useState ([]);
  const getData = async() => {
    const userToken = localStorage.getItem('token')
    axios.defaults.headers.common['Authorization'] = userToken
    await axios.post('https://digisailor.loca.lt/api/Auth/admin/service_list')
    .then((res) => {
      setserviceData(res.data.data)
      console.log(servicelist)
    }).catch((err)=>{
      console.log(err)
    })
}
useEffect(()=>{
    getData();
}, []);

  var service = servicelist.map((row)=>{ 
    return (<option key={row._id} value={row._id}>{row.name}</option>)
  });

 
  return (
    <>
    <div className="container">
        <div className="row">
            <form  onSubmit={handleSubmit}>
                <div className="row">
                    <h1 style={{alignItems: "center"}}>Add Doctor</h1> 
                    <div className="col-md-4">
                        <label>Doctor Name</label>
                    <input type="text" className="form-control" value={name} onChange={handleChange} placeholder="Doctor Name" name="name" />
                    </div>
                    <div className="col-md-4">
                        <label>Age</label>
                    <input type="number" className="form-control" value={age} onChange={handleChange} placeholder="Age" name="age" />
                    </div>
                    <div className="col-md-4">
                        <label>Gender</label>
                        <select className="form-control" value={gender} onChange={handleChange} placeholder="Gender" name="gender">
                            <option value="">--Select--</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                            </select>
                    {/* <input type="text" className="form-control" value={gender} onChange={handleChange} placeholder="Gender" name="gender" /> */}
                    </div>
                    <div className="col-md-4">
                        <label>Experience</label>
                    <input type="text" className="form-control" value={experience} onChange={handleChange} placeholder="Experience" name="experience" />
                    </div>
                    <div className="col-md-4">
                        <label>Mobile</label>
                    <input type="number" className="form-control" value={mobile} onChange={handleChange} placeholder="Mobile" name="mobile" />
                    </div>
                    <div className="col-md-4">
                        <label>Email Id</label>
                    <input type="email" className="form-control" value={email} onChange={handleChange} placeholder="Email Id" name="email" />
                    </div>
                    <div className="col-md-4">
                        <label>Doctor ID</label>
                    <input type="text" className="form-control" value={doctor_id} onChange={handleChange} placeholder="Doctor ID" name="doctor_id" />
                    </div>
                    <div className="col-md-4">
                        <label>Mode Of Consultant</label>
                        <select className="form-control" multiple={true} value={mode_of_consultant} onChange={handleSelectChange}  name="mode_of_consultant">
                          {service} 
                        </select>  
                    {/* <input type="text" className="form-control" value={mode_of_consultant} onChange={handleChange} placeholder="Mode of Consultant" name="mode_of_consultant" /> */}
                    </div>
                    <div className="col-md-4">
                        <label>Available Time</label>
                    <input type="text" className="form-control" value={available_time} onChange={handleChange} placeholder="Time" name="available_time" />
                    </div>
                    <div className="col-md-4">
                        <label>Clinic Name</label>
                    <input type="text" className="form-control"  value={clinic_name} onChange={handleChange}placeholder="Clinic Name" name="clinic_name" />
                    </div>
                    <div className="col-md-4">
                        <label>Clinic Address</label>
                    <input type="text" className="form-control" value={clinic_address} onChange={handleChange} placeholder="Company Address" name="clinic_address" />
                    </div>
                    <div className="col-md-4">
                        <label>Password</label>
                    <input type="text" className="form-control" value={password} onChange={handleChange} placeholder="Enter Your Password" name="password" />
                    </div>
                    <div className="col-md-12" align="center" style={{padding : "10px"}}><button onClick={handleSubmit} type="button" class="btn btn-success">Save</button></div>
                </div>
            </form>
        </div>
    </div>
    </>
  );
};

export default AddDoctor;
